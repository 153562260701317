<template>
  <div class="bg p-3">
    <h3>Room</h3>
    Room Id: {{ roomId }}<br/>
    Room: {{ room | jsonstringify }}<br/>
    <div v-if="room">
      Select user:
      <div v-for="u in room.users" v-bind:key="u">
        <input type="radio" :id="u" :value="u" v-model="userId"/>&nbsp;
        <label :for="u">{{ u }}</label>
      </div>
    </div>
    <p>Status: {{ status }}</p>
    <p>Data Status: {{ dataStatus }}</p>
    <p>Data: {{ data }} </p>
    <button class="bn btn-primary" @click="sendUserData">Send User Data</button>
    <div>
      <button class="bn btn-primary" @click="mute(true)" v-if="!muted">Mute On</button>
      <button class="bn btn-primary" @click="mute(false)" v-else>Mute Off</button>
    </div>
  </div>
</template>

<script>
import { db } from '@/services/db';
import { getLog } from "@/services/log";
let log = getLog('test');

export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      roomId: "01rdgM8tzIFGATAG71B0",
      room: null,
      userId: "",
      data: "",
      status: "",
      dataStatus: "",
      muted: false,
    }
  },
  watch: {
    userId() {
      this.joinRoom();
    }
  },
  mounted() {
    if (this.$route.query.roomId)
      this.roomId = this.$route.query.roomId;
    if (this.$route.query.userId)
      this.userId = this.$route.query.userId;
    log.log(`Mounted room:${this.roomId} user:${this.userId}`);
    this.loadRoom(this.roomId);
  },
  beforeDestroy() {
    this.close()
  },
  methods: {
    // Room
    async loadRoom(roomId) {
      log.log("loadRoom");
      return this.$bind("room", db.collection("LiveRooms").doc(roomId)).then((room) => {
        if (!room)
          log.error("Room does not exist");
        log.log("Room loaded", room);
        this.joinRoom();
      });
    },
    joinRoom() {
      if (!this.roomId || !this.userId)
        return;
      if (window.webkit) {
        window.actions = { webrtcNative: this };
        window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"joinRoom", roomId:this.roomId, userId:this.userId, room:this.room});
      }
    },
    close() {
      if (window.webkit) {
          window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"close"});
      }
    },
    onData(data) {
      log.log("onData", data);
      this.data = data;
    },
    onStatus(data) {
      log.log("onStatus", data);
      this.status = data.status;
    },
    onDataStatus(data) {
      log.log("onDataStatus", data);
      this.dataStatus = data.status;
      if (this.dataStatus == "connecting" || this.dataStatus == "open")
        this.sendUserData();
    },
    sendUserData() {
      if (window.webkit) {
          window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"sendData", data: {user:{name: "Native WebRTC iOS", muted: false, invisible: false}}});
      }
    },
    mute(on) {
      this.muted = on;
      if (window.webkit) {
          window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"mute", on:on});
      }
    }
  }
}
</script>

<style scoped>

.bg {
  background-color: powderblue;
}

</style>
